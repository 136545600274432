import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { AuthService } from "./services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { EnterNameComponent } from "./modals/enter-name/enter-name.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  
	loading = true;
	err = "";
	params: any = {}

  constructor(
  	private authService: AuthService, 
  	public dialog: MatDialog,
  	private router: Router,
  	private route: ActivatedRoute) {
      if (window.location.hash.length > 1) {
	      const querySource = window.location.hash.substring(1);
	      const parts: any = JSON.parse(
	        '{"' +
	          decodeURI(querySource)
	            .replace(/"/g, '\\"')
	            .replace(/&/g, '","')
	            .replace(/=/g, '":"') +
	          '"}'
	      );

        const token = parts.token;
        localStorage.setItem('auth_token', token);
        sessionStorage.removeItem('dismissed_team_register')

        window.location.hash = "";
    }
  }

    ngOnInit(): void {
			this.route.params
				.subscribe(
					params => {
						let keys = Object.keys(params);
						if (keys.length) {
							for (let k of keys) {
								if (k === "ver") continue;
								else this.params[k] = params[k];
							}
						}
					})
			setTimeout(this.versionCheck.bind(this), 200);
			this.refreshCredentials();
    }

    versionCheck() {
		// console.log("Checking version...")
		this.authService.getCurrentVersion()
			.subscribe(
				(res: string) => {
					let ver = localStorage.getItem('api_version')
					if (ver !== res) {
						localStorage.setItem('api_version', res)
						let p = ""
						let keys = Object.keys(this.params);
						for (let k of keys) {
							p = `${p}&${k}=${this.params[k]}`;
						}
						let loc = window.location
						window.location.href = `${loc.origin}${loc.pathname}?ver=${res}${p}`;
					}
					else {
						let token = this.authService.getToken();
						this.router.navigate(
							[],
							{
								relativeTo: this.route,
								queryParams: this.params
							})
						this.loading = false;
					}
				},
				err => {
					this.err = err.error.detail;
					this.loading = false;
				})
	}

	// This is a workaround to prevent Safari and other browsers from 
	// deleting client_id and auth_token after "7 days of inactivity" 
	refreshCredentials() {
		let clientid = localStorage.getItem('kt_client_id')
		let auth_token = localStorage.getItem('auth_token')
		if (clientid) {
			localStorage.removeItem('kt_client_id')
			localStorage.setItem('kt_client_id', clientid)
		}
		if (auth_token) {
			localStorage.removeItem('auth_token')
			localStorage.setItem('auth_token', auth_token)
		}
	}
}
