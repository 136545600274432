import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { GameService, SaveResult } from "../services/game.service";

@Component({
  selector: "app-link-join",
  templateUrl: "./link-join.component.html",
  styleUrls: ["./link-join.component.scss"],
})
export class LinkJoinComponent implements OnInit {
  gameid: string;
  team_code: string;
  name = "";
  err = "";

  emsg = "Invalid or expired join link. Ask one of your teammates to invite you again!";

  loading = true;
  loadingMessage = "Finding your game..."

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private gameServe: GameService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;
    let tok = localStorage.getItem("auth_token")

    this.gameid = routeParams.id;
    this.team_code = routeParams.t_id;

    if (tok) this.nameCheck();
    else this.err = this.emsg;
  }

  nameCheck() {
    this.authService.nameCheck().subscribe(
      (res: any) => {
        if (res.status === "success") {
          if (res.message) this.authService.setToken(res.message);
          this.joinGame();
        }
        else if (res.status === "no-name") this.loading = false;
        else {
          this.err = this.emsg;
          this.loading = false;
        }
      },
      (err) => {
        this.err = this.emsg
        this.loading = false;
      }
    );
  }

  setName() {
    if (!this.name) return;
    this.loading = true;
    this.authService.enterName(this.name).subscribe(
      (res: any) => {
        this.authService.setToken(res.message);
        this.joinGame();
      },
      (err) => {
        this.err = err.error.detail;
        this.loading = false;
      }
    );
  }

  joinGame() {
    this.loadingMessage = "Joining Game..."
    this.loading = true;
    this.gameServe.joinGameById(this.gameid, this.team_code).subscribe(
      (res: any) => {
        if (res?.status === "success") {
          this.router.navigate(["game", res.game_id]);
        }
      },
      (err) => {
        this.err = err.error.detail;
        this.loading = false;
      }
    );
  }
}
