import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SaveResult } from './game.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  rootUri = environment.root_uri;

  constructor(
    private http: HttpClient
  ) { }

  MagicLink(player_name: string, email: string): Observable<SaveResult> {
    return this.http.post<SaveResult>(this.rootUri + '/api/player/registerplayer', {player_name, email});
  }

  registerPlayer(player_name: string, email: string, phone: string) {
  	return this.http.post(`${this.rootUri}/api/player/registerplayer`, {player_name, email, phone});
  }

  getToken(): string {
    return localStorage.getItem('auth_token');
  }

  getPlayer() {
  	let token = this.getToken()
  	return token ? this.parseJwt(token) : null;
  }

  setToken(token: string): void {
    if (!token) {
      localStorage.removeItem('auth_token');
    } else {
      localStorage.setItem('auth_token', token);
    }
  }

  updateToken() {
  	return this.http.post(`${this.rootUri}/api/player/update-token`, {})
  }

  nameCheck(): Observable<SaveResult> {
    return this.http.get<SaveResult>(this.rootUri + '/api/player/name-check');
  }

  enterName(name: string): Observable<SaveResult> {
    return this.http.put<SaveResult>(this.rootUri + '/api/player/set-name', {name});
  }

  addContact(type: string, contact: string) {
  	let postBody: any = {}
  	postBody[type] = contact;
  	return this.http.post(`${this.rootUri}/api/player/add-contact`, postBody);
  }

  verifyUpdate(code: string) {
  	return this.http.post(`${this.rootUri}/api/player/confirm-contact`, {code});
  }

  contactCheck(type: 'phone' | 'email', contact: string) {
  	return this.http.get(this.rootUri + `/api/player/contact-check?${type}=${contact}`);
  }

  verifyCode(code: string, playerid: string) {
  	return this.http.post(`${this.rootUri}/api/player/validate-code`, {code, playerid})
  }

  logout(client_id = "") {
  	if (!client_id) client_id = localStorage.getItem('kt_client_id');
  	return this.http.post(`${this.rootUri}/api/player/logout`, {client_id});
  }

  getCurrentVersion() {
    return this.http.get(`${this.rootUri}/api/version?r=${Math.random()}`)
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
}
